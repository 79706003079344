import React from 'react'
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons"

class AboutSummary extends React.Component {
  render() {
    return (
      <div>
        <h2 className="text-xl mb-4">About me</h2>
          <img className="mx-auto object-center rounded-full h-32 w-32" src="/img/profile.jpg" alt="profile" />
          <p className="text-lg text-center">よしのり</p>
          <section className="mt-2">
            <p className="text-sm mt-2">
              AWSベースのコンテナ・サーバレスアプリケーション構築、IaC、DevOps構築がメインスキルです。<br />
              セキュリティ確保(アプリケーション・AWSアカウント)、冗長構成、ログ・監視・通知まで十分に整備された環境を構築できます。
            </p>
            <Link 
              to="/about"
              className="text-sm text-blue-600  underline"
            >
              プロフィール詳細はこちら
            </Link>
         </section>

          <section className="mt-5">
            <div className="flex flex-wrap justify-around px-12">
              <a target="_blank" href="https://twitter.com/yoshinori_tokyo" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="2x" color="#15aabf" />
              </a>
              <a target="_blank" href="https://github.com/YoshinoriSatoh" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
              <a target="_blank" className="text-blue-500 my-auto" href="https://zenn.dev/yoshinori_satoh" rel="noreferrer">
                Zenn
              </a>
              <a target="_blank" className="text-blue-500 my-auto" href="https://qiita.com/YoshinoriSatoh" rel="noreferrer">
                Qiita
              </a>
            </div>
         </section>
      </div>
    )
  }
}

export default AboutSummary